import vue from 'vue';
import vuex from 'vuex';

import { create as createRoot } from './modules/root.js';

import { loadFonts } from '../FontManager.js';
import { getTemplate as getWatermarkTemplate } from '../modules/WatermarkModuleExtra.vue';

vue.use(vuex);

export async function create(config, modules = {}) {
	const root = await createRoot(config);

	if(!root.modules) root.modules = {};
	
	for(const key in modules) {
		const module = await modules[key].create(config);
		if(module.root) {
			root.state = {...root.state, ...module.state};
			root.getters = {...root.getters, ...module.getters};
			root.mutations = {...root.mutations, ...module.mutations};
			root.actions = {...root.actions, ...module.actions};
		}
		else {
			root.modules[key] = module;
		}
	}

	return new vuex.Store(root);
}

export function init($store, config) {
	loadFonts(config.shared.fonts || []);
	addWatermarkIfNotExists($store);
	if($store.state.type == 'logo') {
		removeDefaultContentFromLogoMode($store);
		$store.state.dirty = false;
	}
}

/**
 * Check if watermark module exists in none pro mode
 * @param {Store} $store
 */
function addWatermarkIfNotExists($store) {
	if(
		$store.state.type != 'pro'
		&&
		$store.state.data.modules.every(m => m.class !== 'WatermarkModule')
	) {
		$store.dispatch('data/module', getWatermarkTemplate());
	}
}

/**
 * Check if default content exists in logo mode
 * @param {Store} $store
 */
function removeDefaultContentFromLogoMode($store) {
	$store.state.data.modules.forEach((m, i) => {
		if(
			m.class === 'WatermarkModule' ||
			(
				typeof m.props.images != 'undefined' &&
				(
					m.props.images[0].path == '/dist/img/solarfox.png' ||
					m.props.images[0].path == '/dist/img/FoxDesigner-Icon.png'
				)
			)
		) {
			$store.state.data.modules.splice(i, 1);
			removeDefaultContentFromLogoMode($store);
		}
	});
}
