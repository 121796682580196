<template>
  <div :id="id" class="fd-text-module" :style="style"> <div v-html="this.displayText"></div> </div>
</template>

<script>
import BaseModule from './BaseModule.vue';
import {
	_elementFromTemplate,
	_calc,
	_decodeHtml,
	_pipe,
} from '../util/helper.js';

/**
 * This function is called for every module on creation to normalize or fix
 * deprecated state.
 */
function normalize(props) {
	if (!props.factor) {
		props.factor = 24;
	}
}

export default {
	name: 'TextModule',
	extends: BaseModule,
	normalize,
	computed: {
		id() {
			return 'fd-text-module' + this.module.mid;
		},
		style() {
			return {
				fontSize: this.module.props.factor + 'px',
				opacity: (this.module.logo && this.$store.state.mode == 'edit' ? 0.5 : 1)
			};
		},
		displayText() {
			return _pipe(
				this.module.props.text,
				txt => this.replaceWithVars(txt),
				txt => this.calc(txt),
				txt => this.ifelse(txt)
			);
		},
	},
	mounted() {
		this.$root.$on('updateText' + this.module.mid, _ => {
			document.getElementById(this.id).innerHTML = this.displayText;
		});
	},
	methods: {
		replaceWithVars(txt) {
			const variables = this.$store.state.shared.variables;
			for (let key in variables) {
				if (key === 'CurrentMonth') {
					variables[key] =
						(this.$root.$options.lang && this.$root.$options.lang[variables[key]]) ||
						variables[key];
				}
				txt = txt.replace(new RegExp(key, 'g'), variables[key]);
			}
			return txt;
		},

		calc(txt) {
			let regex = /(´|&acute;)(.*?)(´|&acute;)(\{[.,‘)]?[.,]?[\d]?\})?/g;
			let match;
			let matches = [];
			txt = txt.replace('&lsquo;', '‘');
			while ((match = regex.exec(txt)) !== null) {
				matches.push({
					replace: match[0],
					calc: _elementFromTemplate(`<div>${match[2]}</div>`).textContent,
					settings: match[4],
				});
			}

			matches.forEach(match => {
				let nsep = '';
				let msep = ',';
				let dcas = 2;

				if (match.settings) {
					switch (match.settings.length) {
						case 3:
							if (!isNaN(parseInt(match.settings[1])))
								dcas = parseInt(match.settings[1]);
							else msep = match.settings[1];
							break;

						case 4:
							if (!isNaN(parseInt(match.settings[2]))) {
								msep = match.settings[1];
								dcas = parseInt(match.settings[2]);
							} else {
								nsep = match.settings[1];
								msep = match.settings[2];
							}
							break;

						case 5:
							nsep = match.settings[1];
							msep = match.settings[2];
							dcas = match.settings[3];
							break;
					}
				}

				txt = txt.replace(match.replace, _calc(match.calc, msep, dcas, nsep));
			});
			return txt;
		},

		ifelse(txt) {
			let regex = /\{if\|(.*?)\}((.|\n)*?)\{endif\}/gi;
			let ifregex = /if\|(.*?)\}((.|\n)*?)\{e/gi;
			let elseregex = /\{else\}((.|\n)*?)\{endif\}/gi;
			let match;
			while ((match = regex.exec(txt)) !== null) {
				let all = match[0];
				let ifcon;
				while ((match = ifregex.exec(all)) !== null) {
					ifcon = this.testCondition(match[1]);
					if (ifcon) {
						txt = txt.replace(all, match[2]);
						regex.lastIndex = 0;
						ifregex.lastIndex = 0;
						break;
					}
				}
				if (!ifcon) {
					while ((match = elseregex.exec(all)) !== null) {
						ifcon = true;
						txt = txt.replace(all, match[1]);
						regex.lastIndex = 0;
						elseregex.lastIndex = 0;
						break;
					}
				}
				if (!ifcon) {
					txt = txt.replace(all, '');
					regex.lastIndex = 0;
				}
			}

			this.removeEmptyElements(txt);

			return txt;
		},

		testCondition(con) {
			con = _decodeHtml(con);

			try {
				eval('con = ' + con);
			} catch (e) {}

			return !!con;
		},

		removeEmptyElements(txt) {
			var div = _elementFromTemplate(`<div>${txt}</div>`);

			var removeIfEmpty = function(node) {
				if (node.textContent == '') node.remove();
				else [...node.childNodes].forEach(removeIfEmpty);
			};

			[...div.childNodes].forEach(removeIfEmpty);

			return div.innerHTML.trim();
		},
	},
};
</script>

<style lang="scss">
@import '../../scss/TextModuleInnerStyle.scss';

.fd-text-module {
	@extend .fd-text-module-inner-style;
	outline: none;
}
</style>
